.PauseMenu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #36a9;
  user-select: none;
  cursor: default;
  backdrop-filter: blur(8px);
}

.PauseMenu:not(.paused) {
  display: none;
}

.PauseMenu.paused {
  display: block;
}

.PauseMenu h1 {
  position: absolute;
  font-family: "Montserrat Black", sans-serif;
  font-size: 6em;
  color: #fff;
  margin: auto 0;
  text-align: center;
  bottom: 50%;
  width: 100%;
}

.PauseMenu h2 {
  position: absolute;
  width: 100%;
  font-family: "Montserrat light", sans-serif;
  font-size: 2em;
  color: #fff;
  margin: auto 0;
  text-align: center;
  top: 50%;
}

.PauseMenu .TopLeft {
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
}

.PauseMenu .TopLeft .button {
  font-size: 2em;
  color: #fff;
  cursor: pointer;
  transition: all 0.05s ease;
  transform-origin: 0% 50%;
  display: inline-block;
}

.PauseMenu .TopLeft .button:hover {
  transform: scale(1.1);
  color: #ff0;
}

.PauseMenu .TopLeft .button:active {
  transform: scale(1.15);
}
